import './App.css';
import logo from './assets/destination.png'

function App() {
  return (
    <div className="App bg-[#03A9F4] h-[100vh] w-[100vw] flex justify-center items-center">
      <div className='flex flex-col items-center text-white cabin'>
        <img src={logo} alt="" className='h-[70px] md:h-[70px] lg:h-[170px]' />
        <div className='mt-5 text-[] md:text-[28px]'>
          <span className='pacifico text-[40px] md:text-[100px]'>
            Coming Soon...
          </span>
          <p className='mt-5'>
            Contact Us <br /> @<br />
          </p>
          <span className='hover:cursor-pointer ' onClick={() => 'mailto:info@beyonddestination.com'}>info@beyonddestination.com</span>
        </div>
      </div>
    </div>
  );
}

export default App;
